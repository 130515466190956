<template>
  <div class="about d-flex flex-column">
    <!-- <div class="picture">
      <v-img
        src="https://via.placeholder.com/2000"
        lazy-src="https://via.placeholder.com/300.webp/?text=loading"
        max-height="70vh"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div> -->
    <v-col style="margin-top: 5%;">
      <div class="title text-h2 text-center text-decoration-underline">School Fees</div>
      <div class="article">

      <v-card style="padding: 1.3em;">
        <div class="title text-h4 text-center">
          School Fees Detail
        </div>
        <br>
        <div class="text-justify">
          <v-row>
            <v-col md="4" sm="12">
              <v-card>
                <v-list>
                  <v-list-item><strong>Academic Group</strong></v-list-item>
                  <v-divider></v-divider>
                  <br>
                  <v-list-item-group v-model="index" color="primary">
                    <v-list-item>Kindergarten</v-list-item>
                    <v-list-item>Primary</v-list-item>
                    <v-list-item>Secondary</v-list-item>
                    <v-list-item>Higher-Level</v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
            <v-col md="8" sm="12">
              <table border=1 class="table">
                <thead>
                  <th colspan=2 class="table-title">
                    {{tableData[index].title}} Fees
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td colspan=2 style="height: 10px; border-left-color: white; border-right-color: white;">
                    </td>
                  </tr>
                  <tr>
                    <td>Admission Fee</td>
                    <td class="nominal">{{tableData[index].admission.toLocaleString('en-US', {style: 'currency', currency: 'IDR'})}}</td>
                  </tr>
                  <tr>
                    <td>Tuition Fee</td>
                    <td class="nominal">{{tableData[index].tuition.toLocaleString('en-US', {style: 'currency', currency: 'IDR'})}} / month</td>
                  </tr>
                  <tr>
                    <td>Miscellaneous Fee</td>
                    <td class="nominal">{{tableData[index].miscellaneous.toLocaleString('en-US', {style: 'currency', currency: 'IDR'})}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan=2 style="height: 10px; border-left-color: white; border-right-color: white;">
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Total Admission</strong></td>
                    <td class="nominal">{{(tableData[index].admission + tableData[index].miscellaneous).toLocaleString('en-US', {style: 'currency', currency: 'IDR'})}}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Tuition / 1 Academic Year</strong></td>
                    <td class="nominal">{{(tableData[index].tuition * 12).toLocaleString('en-US', {style: 'currency', currency: 'IDR'})}}</td>
                  </tr>
                </tfoot>
              </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=12 md=12 sm=12>
              <small>*note: The fee breakdown above may not reflect subsequent school fee</small>
            </v-col>
          </v-row>
        </div>
      </v-card>

      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  data: () => ({
    index: 0,
    tableTitle: 'Kindergarten Fees',
    tableData: [
      {
        title: 'Kindergarten',
        admission: 2000000,
        tuition: 2000000,
        miscellaneous: 2000000
      },
      {
        title: 'Primary',
        admission: 4000000,
        tuition: 4000000,
        miscellaneous: 4000000
      },
      {
        title: 'Secondary',
        admission: 6000000,
        tuition: 6000000,
        miscellaneous: 6000000
      },
      {
        title: 'Higher Level',
        admission: 8000000,
        tuition: 8000000,
        miscellaneous: 8000000
      }
    ]
  })
}
</script>

<style>
.about {
  /*margin-top: -64px;*/
  min-height: 100vh;
}
.picture {
  height: 70vh;
}
.title {
  padding-top: 50px;
}
.article {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 250px;
  padding-right: 250px;
}
.table {
  width: 100%;
}
table, th, td {
  padding: 0.7em;
  border: 2px solid #636e72;
  border-collapse: collapse;
  letter-spacing: 0.1em;
  font-size: 12pt;
}

th.table-title {
  text-align: center;
}

td.nominal {
  text-align: right;
}
</style>
