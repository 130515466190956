<template>
  <div class="about d-flex flex-column">
    <div class="picture" elevation="24">
      <v-img
        src="@/assets/images/header3.jpg"
        lazy-src="https://via.placeholder.com/300.webp/?text=loading"
        max-height="70vh"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <v-col>
      <div class="title text-h2 text-center text-decoration-underline">Our School Activities</div>
      <div class="article text-justify">
        <v-row>
          <v-col
            v-for="i in 9"
            :key="i"
            class="d-flex child-flex"
            cols=4
          >
            <v-card>
              <v-img :src="require('..//assets//images//img'+i+'.jpg')" width=500 height=400></v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </div>
</template>

<script>
export default {
  name: 'gallery'
}
</script>

<style>
.about {
  /*margin-top: -112px;*/
  min-height: 100vh;
}
.picture {
  height: 70vh;
}
.title {
  padding-top: 50px;
}
.article {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 250px;
  padding-right: 250px;
}
</style>
