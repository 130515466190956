<template>
  <h1>Redirecting to class rules</h1>
</template>

<script>
// import pdf from 'vue-pdf'

export default {
  components: {
    // pdf
  },
  mounted () {
    window.location.replace('https://is3.cloudhost.id/anderson1/16158106.pdf')
  }
}
</script>
