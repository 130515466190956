<template>
  <div class="about d-flex flex-column">
    <div class="picture">
      <v-img
        src="https://via.placeholder.com/2000"
        lazy-src="https://via.placeholder.com/300.webp/?text=loading"
        max-height="70vh"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <v-col>
      <div class="title text-h2 text-center text-decoration-underline">{{title[currentIdx]}}</div>
      <div class="article text-justify">
        <v-row v-for="i in 3" :key="'row'+i">
          <v-col v-for="j in 3" :key="'col'+i+j">
            <v-card>
              <v-img src="https://via.placeholder.com/200x100"></v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </div>
</template>

<script>
  export default {
    name: 'gallery',

    data: () => ({
      currentIdx: 0,
      title: [
        "Kindergarten",
        "Primary Programme",
        "Secondary Programme",
        "High-School Programme",
        "Non-Academic Programme"
      ],
    }),
    watch: {
      '$route': function () {
        switch(this.$route.name)
        {
          case 'Kindergarten': this.currentIdx = 0;
                          break;
          case 'Primary':      this.currentIdx = 1;
                          break;
          case 'Secondary':    this.currentIdx = 2;
                          break;
          case 'HigherLevel':  this.currentIdx = 3;
                          break;
          case 'NonAcademic':  this.currentIdx = 4;
                          break;
        }
      }
    }
  }
</script>

<style>
.about {
  margin-top: -112px;
  min-height: 100vh;
}
.picture {
  height: 70vh;
}
.title {
  padding-top: 50px;
}
.article {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 250px;
  padding-right: 250px;
}
</style>
